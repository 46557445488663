@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
    font-family: Monarda; 
    src: url("./fonts/Monarda.ttf"); 
  }

  @font-face{
    font-family: Afterglow; 
    src: url("./fonts/Afterglow.ttf"); 
  }

#root {
  position: relative; 
  overflow-x: hidden;
}

#modal {
  direction: rtl; 
}

#modal-information {
  direction: ltr; 
}